import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { cssVars } from '@/styles/Themes/cssVars';
import { Transition } from '@/styles/Themes/KeyFrames';
import { borders, radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import Screen from '@/styles/Themes/Screen';
import Text from 'src/styles/Themes/Text';
import styled, { DefaultTheme } from 'styled-components';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import { ReactNode } from 'react';

interface Iindicator extends DefaultTheme {
    isOpen: boolean;
}

export const ArrowDown = styled(DropdownArrow)<Iindicator>`
    width: 32px;
    ${({ isOpen }) => isOpen && 'transform: rotate(-180deg);'};
    ${Transition.normal};
`;

export const IconClose = styled(CloseIcon)`
    cursor: pointer;
    color: ${cssVars.fg_gray3_dark1};
    width: 8px;
    height: 8px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
`;

export const Label = styled.div`
    ${Text.Mobile.large.medium};
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.desktop} {
        ${Text.Desktop.normal.regular};
    }
`;

export const InputSearchWrapper = styled.label`
    position: relative;
    width: 100%;
    padding: 0 10px 0 20px;
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    border-radius: ${radius.large};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    ${Screen.desktop} {
        flex: 1;
        background-color: ${cssVars.bg_white_blue};
        margin: 0;
        border: none;
        border-right: ${borders.small} ${cssVars.fg_gray3_dark1};
        border-radius: 0;
        &:first-of-type {
            border-radius: ${radius.large} 0 0 ${radius.large};
        }
    }
`;

export const InputSearchContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 50px;

    ${Screen.desktop} {
        height: 60px;
    }
`;

//start

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    border-radius: ${radius.large};
    ${Screen.ipad} {
        width: auto;
    }
`;

export const InputSearchIcons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 48px;
    ${Screen.desktop} {
        margin-right: 0;
    }
`;

export const InputSearch = styled.input`
    appearance: none;
    ${Text.Desktop.large.medium};
    color: ${cssVars.fg_gray1_white};
    border: none;
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 0;
    width: 100%;

    &:focus-visible {
        outline: none;
        appearance: none;
    }
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    opacity: 0.5;
    height: 100%;
`;

export const ButtonClose = styled.button`
    appearance: none;
    cursor: pointer;
    border: none;
    background-color: ${cssVars.bg_white_dark3};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 100%;

    &:hover {
        border: none;
    }
`;

export const ButtonArrow = styled.button`
    appearance: none;
    cursor: pointer;
    border: none;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background-color: transparent;

    &:hover {
        border: none;
    }
`;

export const ResultsContainer = styled.div`
    ${Screen.desktop} {
        position: absolute;
        width: 100%;
        max-height: 340px;
        left: 0;
        top: 62px;
        border-radius: ${radius.small};
        padding: 10px;
        background-color: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_container};
        z-index: 3;
        transition: all 0.3s ease;

        @keyframes appearance {
            from {
                transform: translateY(-2px);
                opacity: 0;
            }
            to {
                transform: translateY(0);
                opacity: 100%;
            }
        }

        animation-duration: 0.3s;
        animation-name: appearance;
        animation-timing-function: ease;

        &.hide {
            opacity: 0;
            @keyframes hide {
                from {
                    transform: translateY(0);
                    opacity: 100%;
                }
                to {
                    transform: translateY(-2px);
                    opacity: 0;
                }
            }
            animation-duration: 0.2s;
            animation-name: hide;
            animation-timing-function: ease;
        }
    }
`;

export const ResultsFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 28px;
    margin: 19px auto 30px;

    ${Screen.ipad} {
        height: 32px;
    }

    ${Screen.desktop} {
        margin: 15px auto 19px;
        height: 28px;
    }
`;

export const ResultsItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 52px;
    align-items: center;
    ${Text.Desktop.normal.medium};
    color: ${cssVars.fg_gray2_white};
    cursor: pointer;
    border-radius: ${radius.small};
    border-bottom: ${borders.small} ${cssVars.bg_ash_dark2};

    &:hover {
        background-color: ${cssVars.bg_ash_normal};
        border-color: transparent;
        color: ${Palette.green.normal};
        > div {
            color: ${Palette.green.normal};
            > label {
                color: ${Palette.green.normal};
            }
            > span {
                color: ${Palette.green.normal};
            }
        }
    }
    ${Screen.desktop} {
        height: 45px;
    }
`;

export const ScrollResultContainer = styled.div<{ footer: ReactNode }>`
    padding: 10px 5px 10px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    height: ${({ footer }) => (footer ? '328px' : '388px')};

    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-track {
        background-color: ${cssVars.bg_ash_blueDark};
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        height: 30px;
        background: ${Palette.gray.gray3};
        border-radius: 10px;
    }

    ${Screen.desktop} {
        height: ${({ footer }) => (footer ? '270px' : '320px')};
        padding: 0 5px;
    }
`;

export const MobileAbsoluteContainer = styled.div<{ isVisible: boolean }>`
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: -24px;
    left: -20px;
    right: -20px;
    padding: 0 20px 24px;
    background-color: ${cssVars.bg_white_dark3};
    transition: all ease 0.4s;
    transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(100%)')};

    @keyframes slideUp {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }
    @keyframes slideDown {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100%);
        }
    }

    animation-duration: 0.2s;
    animation-name: slideUp;
    animation-timing-function: ease;

    &.hide {
        opacity: 0;
        @keyframes hide {
            from {
                transform: translateY(0);
                opacity: 100%;
            }
            to {
                transform: translateY(-2px);
                opacity: 0;
            }
        }
        animation-duration: 0.2s;
        animation-name: slideDown;
        animation-timing-function: ease;
    }
`;

export const MobileCloseBar = styled.div`
    position: absolute;
    top: 8px;
    left: 45%;
    width: 45px;
    height: 5px;
    appearance: none;
    border: none;
    background-color: ${cssVars.bg_gray4_dark1};
    border-radius: ${radius.small};

    ${Screen.desktop} {
        display: none;
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 32px;
    padding: 0 15px;
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    border-radius: ${radius.large};
    background-color: ${cssVars.bg_white_dark3};
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.desktop} {
        display: none;
    }
`;

export const ListTitle = styled.div`
    ${Text.Mobile.heading4.normal};
    color: ${cssVars.fg_gray1_white};
    padding: 0 9px 20px;

    ${Screen.desktop} {
        display: none;
    }
`;

interface ICloseIcon extends DefaultTheme {
    onClick: () => void;
}

export const CloseButtonWithIcon = styled(CloseIcon)``;

export const CloseButton = styled.div<ICloseIcon>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${cssVars.bg_ash_normal};
    border-radius: ${radius.huge};
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 40px;
        height: 40px;
    }

    ${Screen.desktop} {
        display: none;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;
