import { Button } from '@/common/ui';
import { SearchIcon } from '@/shared/images/icons/SearchIcon';
import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';

export const SearchBarForm = styled.div`
    width: 100%;
    display: block;

    ${Screen.desktop} {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        border-radius: ${radius.large};
    }
`;
export const SearchBarButton = styled(Button)`
    padding: 0;
    border-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: ${Palette.white.normal};

    ${Screen.desktop} {
        box-shadow: none;
        border-radius: 0 32px 32px 0;
        width: 16%;
        max-width: 130px;
        height: 60px;
        max-height: 60px;
        padding: 0 20px;
        font-size: ${Text.Desktop.large.medium};
    }
`;

export const SearchBarButtonIcon = styled(SearchIcon)`
    width: 14px;
    height: 13px;
    margin: 16px 10px;

    ${Screen.desktop} {
        margin: 0 6px;
    }
`;
