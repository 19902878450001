import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';
import { radius } from 'src/styles/Themes/Metrics';
import { borders } from '@/styles/Themes/Metrics';
import { Palette } from '@/styles/Themes/Palette';
import { DropdownArrow } from 'src/shared/images/icons/DropdownArrow';
import { Transition } from '@/styles/Themes/KeyFrames';

export const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
    box-sizing: border-box;
    position: relative;
`;

export const Label = styled.label`
    ${Text.Mobile.medium.semibold};
    padding: 5px 0;
    color: ${cssVars.fg_gray1_white};

    ${Screen.ipad} {
        ${Text.Ipad.caption.semibold};
    }
`;

type TControlStyles = {
    isOpen: boolean;
    onClick?: () => void;
    color?: string;
};

export const Control = styled.div<TControlStyles>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: ${({ isOpen }) => (isOpen ? '100%' : '40px')};
    border: ${({ isOpen }) => (isOpen ? 'none' : `${borders.small} ${cssVars.fg_gray3_dark1}`)};
    border-radius: ${({ isOpen }) => (isOpen ? `${radius.medium}` : `${radius.large}`)};
    padding: ${({ isOpen }) => (isOpen ? '1px 15px' : '5px 15px')};
    background-color: ${cssVars.bg_white_dark3};
    color: ${({ color }) => (color ? color : `${cssVars.fg_gray2_gray3}`)};
    align-items: center;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    ${Screen.ipad} {
        height: ${({ isOpen }) => (isOpen ? '100%' : '50px')};
    }
`;

export const IndicatorContainer = styled(DropdownArrow)<TControlStyles>`
    position: relative;
    z-index: 1;
    height: 40px;
    ${({ isOpen }) => isOpen && 'transform: rotate(-180deg);'}
    ${Transition.arrow}
`;

export const DropdownHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const DropdownItems = styled.div<TControlStyles>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    top: 35px;
    width: 100%;
    box-shadow: ${({ isOpen }) => (isOpen ? `${cssVars.shadow_card_default}` : 'none')};
    z-index: 10;
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.normal};
    padding: 0 1px 10px;
`;

export const MenuFirstItem = styled.div`
    display: flex;
    cursor: pointer;
    padding: 0 20px;
    height: 16px;
    width: 90%;
    overflow: hidden;
    ${Text.Mobile.medium.medium};

    ${Screen.ipad} {
        height: 50px;
        ${Text.Ipad.medium.medium};
        align-items: center;
    }
    ${Screen.desktop} {
        ${Text.Desktop.medium.medium};
        padding: 13px 20px;
    }
`;

type TMenuItemStyles = {
    selected: boolean;
};

export const MenuItem = styled.div<TMenuItemStyles>`
    width: 90%;
    cursor: pointer;
    padding: 13px 20px;
    margin: 0 auto;
    border-top: ${borders.small} ${cssVars.bg_ash_darker};
    color: ${({ selected }) => (selected ? Palette.green.darker : 'inherit')};
    ${Text.Mobile.medium.medium};
    overflow: hidden;
    &:first-child {
        pointer-events: none;
        border-top: none;
        padding-left: 10px;
        color: ${cssVars.fg_gray2_white};
    }

    ${Screen.ipad} {
        height: 50px;
        ${Text.Ipad.medium.medium};
    }
    ${Screen.desktop} {
        padding: 15px;
        ${Text.Desktop.medium.medium};
    }

    &:hover,
    &:active {
        background-color: ${cssVars.bg_ash_normal};
        color: ${Palette.green.normal};
        border-radius: ${radius.small};
    }
`;
