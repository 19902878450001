import { getCS, setLS, getLS, LocalStorageTypes } from '@/common/service/storage';
import { ReactElement, useEffect } from 'react';
import { userUpdate } from '@/common/app/utils/iterable';
import { AnalyticsInstance } from '@/common/service/analytics';
import { CredentialResponse } from './GoogleAuth.types';

const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(
        atob(base64Url)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(base64);
};

const handleCredentialResponse = (response: CredentialResponse) => {
    if (!response.credential) {
        return;
    }
    const data = parseJwt(response.credential);
    const payload = {
        email: data.email,
        firstName: data.given_name ? data.given_name : '',
        lastName: data.family_name ? data.family_name : '',
        fullName: data.name ? data.name : '',
        picture: data.picture,
        phoneNumber: '',
        phone: '',
        phone2: '',
        user_id: '',
        userId: '',
    };
    userUpdate(data.email, payload);
    AnalyticsInstance.account.contactInfo(payload);

    if (response.select_by) {
        AnalyticsInstance.OneTapEvent(response.select_by);
    }

    document.cookie = 'user=' + JSON.stringify(payload);
    const contactInfoInLS = getLS(LocalStorageTypes.LS_CONTACT_INFO);

    const payloadLS = {
        userId: null,
        email: data.email,
        first_name: data.given_name,
        last_name: data.family_name,
        phone: '',
        phone2: '',
    };
    if (!contactInfoInLS) {
        setLS(LocalStorageTypes.LS_CONTACT_INFO, payloadLS);
    } else {
        setLS(LocalStorageTypes.LS_CONTACT_INFO, {
            userId: contactInfoInLS.userId,
            email: data.email,
            first_name: data.given_name ? data.given_name : contactInfoInLS.firstName,
            last_name: data.family_name ? data.family_name : contactInfoInLS.lastName,
            phone: contactInfoInLS.phone,
            phone2: contactInfoInLS.secondaryPhone,
        });
    }
};

const GoogleAuth = (): ReactElement => {
    useEffect(() => {
        if (!getCS('user')) {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.async = true;
            script.defer = true;
            script.id = 'googleOneTap';

            document.querySelector('body')?.appendChild(script);
            window.handleCredentialResponse = handleCredentialResponse;
        }
    }, []);

    const isAuth = getCS('user');
    return (
        <>
            {!isAuth && (
                <div
                    id="g_id_onload"
                    data-client_id="706715915018-4novupelq0lre87o6atkotsm9iau7qb5.apps.googleusercontent.com"
                    data-auto_select="true"
                    data-callback="handleCredentialResponse"
                />
            )}
        </>
    );
};

export { GoogleAuth };
export default GoogleAuth;
