import { ReactElement, useRef } from 'react';
import { TTalkToUsRequest, useTalkToUs } from '../service/TalkToUs.service';
import { TalkToUsForm } from './talkToUs/TalkToUsForm/TalkToUsForm';
import { TalkToUsUnavailable } from './talkToUs/TalkToUsUnavailable/TalkToUsUnavailable';
import { useOnClickOutside } from '@/common/service/hooks';
import * as S from './TalkToUs.styles';
import { Portal } from '@/common/ui/portal';

type TalkToUsProps = {
    onClose: () => void;
};

const TalkToUs = ({ onClose }: TalkToUsProps): ReactElement => {
    const { isValidTime, onSubmit, isSuccess, isLoading } = useTalkToUs();
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => onClose());

    const handleSubmit = async (args: TTalkToUsRequest) => {
        if (onSubmit) {
            await onSubmit(args);
        }
    };

    return (
        <Portal>
            <S.Wrapper>
                <S.Modal ref={ref}>
                    <S.Header>
                        <S.SubHeading>
                            Fill out your information and a reservationist will call you right back
                        </S.SubHeading>
                        <S.CloseButton onClick={onClose}>
                            <S.CloseButtonWithIcon />
                        </S.CloseButton>
                    </S.Header>
                    {isValidTime() ? (
                        <TalkToUsForm
                            onSubmit={(args) => handleSubmit(args)}
                            confirm={isSuccess}
                            isLoading={isLoading}
                        />
                    ) : (
                        <TalkToUsUnavailable />
                    )}
                </S.Modal>
            </S.Wrapper>
        </Portal>
    );
};

export { TalkToUs };
