import { css, FlattenSimpleInterpolation } from 'styled-components';
import { Desktop } from './Desktop';
import { Ipad } from './Ipad';
import { Mobile } from './Mobile';
import Screen from '../Screen';

type TFullMedia = {
    heading1: FlattenSimpleInterpolation;
    heading2: FlattenSimpleInterpolation;
    heading3: FlattenSimpleInterpolation;
    heading4: FlattenSimpleInterpolation;
    large: {
        semibold: FlattenSimpleInterpolation;
        medium: FlattenSimpleInterpolation;
        regular: FlattenSimpleInterpolation;
    };
    medium: {
        semibold: FlattenSimpleInterpolation;
        medium: FlattenSimpleInterpolation;
        regular: FlattenSimpleInterpolation;
    };
    normal: {
        semibold: FlattenSimpleInterpolation;
        medium: FlattenSimpleInterpolation;
        regular: FlattenSimpleInterpolation;
    };
    small: {
        semibold: FlattenSimpleInterpolation;
        medium: FlattenSimpleInterpolation;
        regular: FlattenSimpleInterpolation;
    };
    caption: {
        semibold: FlattenSimpleInterpolation;
        medium: FlattenSimpleInterpolation;
    };
};

const Full: TFullMedia = {
    heading1: css`
        ${Mobile.heading1}
        ${Screen.ipad} {
            ${Ipad.heading1}
        }
        ${Screen.desktop} {
            ${Desktop.heading1}
        }
    `,
    heading2: css`
        ${Mobile.heading2}
        ${Screen.ipad} {
            ${Ipad.heading2}
        }
        ${Screen.desktop} {
            ${Desktop.heading2}
        }
    `,
    heading3: css`
        ${Mobile.heading3}
        ${Screen.ipad} {
            ${Ipad.heading3}
        }
        ${Screen.desktop} {
            ${Desktop.heading3}
        }
    `,
    heading4: css`
        ${Mobile.heading4}
        ${Screen.ipad} {
            ${Ipad.heading4}
        }
        ${Screen.desktop} {
            ${Desktop.heading4}
        }
    `,
    large: {
        semibold: css`
            ${Mobile.large.semibold}
            ${Screen.ipad} {
                ${Ipad.large.semibold}
            }
            ${Screen.desktop} {
                ${Desktop.large.semibold}
            }
        `,
        medium: css`
            ${Mobile.large.medium}
            ${Screen.ipad} {
                ${Ipad.large.medium}
            }
            ${Screen.desktop} {
                ${Desktop.large.medium}
            }
        `,
        regular: css`
            ${Mobile.large.regular}
            ${Screen.ipad} {
                ${Ipad.large.regular}
            }
            ${Screen.desktop} {
                ${Desktop.large.regular}
            }
        `,
    },
    medium: {
        semibold: css`
            ${Mobile.medium.semibold}
            ${Screen.ipad} {
                ${Ipad.medium.semibold}
            }
            ${Screen.desktop} {
                ${Desktop.medium.semibold}
            }
        `,
        medium: css`
            ${Mobile.medium.medium}
            ${Screen.ipad} {
                ${Ipad.medium.medium}
            }
            ${Screen.desktop} {
                ${Desktop.medium.medium}
            }
        `,
        regular: css`
            ${Mobile.medium.regular}
            ${Screen.ipad} {
                ${Ipad.medium.regular}
            }
            ${Screen.desktop} {
                ${Desktop.medium.regular}
            }
        `,
    },
    normal: {
        semibold: css`
            ${Mobile.normal.semibold}
            ${Screen.ipad} {
                ${Ipad.normal.semibold}
            }
            ${Screen.desktop} {
                ${Desktop.normal.semibold}
            }
        `,
        medium: css`
            ${Mobile.normal.medium}
            ${Screen.ipad} {
                ${Ipad.normal.medium}
            }
            ${Screen.desktop} {
                ${Desktop.normal.medium}
            }
        `,
        regular: css`
            ${Mobile.normal.regular}
            ${Screen.ipad} {
                ${Ipad.normal.regular}
            }
            ${Screen.desktop} {
                ${Desktop.normal.regular}
            }
        `,
    },
    small: {
        semibold: css`
            ${Mobile.small.semibold}
            ${Screen.ipad} {
                ${Ipad.small.semibold}
            }
            ${Screen.desktop} {
                ${Desktop.small.semibold}
            }
        `,
        medium: css`
            ${Mobile.small.medium}
            ${Screen.ipad} {
                ${Ipad.small.medium}
            }
            ${Screen.desktop} {
                ${Desktop.small.medium}
            }
        `,
        regular: css`
            ${Mobile.small.regular}
            ${Screen.ipad} {
                ${Ipad.small.regular}
            }
            ${Screen.desktop} {
                ${Desktop.small.regular}
            }
        `,
    },
    caption: {
        semibold: css`
            ${Mobile.caption.semibold}
            ${Screen.ipad} {
                ${Ipad.caption.semibold}
            }
            ${Screen.desktop} {
                ${Desktop.caption.semibold}
            }
        `,
        medium: css`
            ${Mobile.caption.medium}
            ${Screen.ipad} {
                ${Ipad.caption.medium}
            }
            ${Screen.desktop} {
                ${Desktop.caption.medium}
            }
        `,
    },
};

export default Full;
