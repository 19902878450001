import styled, { DefaultTheme } from 'styled-components';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';
import Screen from 'src/styles/Themes/Screen';
import { radius } from 'src/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';
import Text from '@/styles/Themes/Text';
import Palette from '@/styles/Themes/Palette';

interface ICloseIcon extends DefaultTheme {
    onClick: () => void;
}

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    ${Screen.desktop} {
        ::-webkit-scrollbar {
            width: 6px;
        }
        ::-webkit-scrollbar-track {
            background-color: ${cssVars.bg_ash_blueDark};
            border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
            height: 30px;
            background: ${Palette.gray.gray3};
            border-radius: 10px;
        }
    }
`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${cssVars.bg_white_dark3};
    width: 340px;
    max-width: 100%;
    position: relative;
    margin: auto;
    box-sizing: border-box;
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_modal};

    ${Screen.ipad} {
        width: 512px;
    }
    ${Screen.desktop} {
        width: 610px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 24px 24px 0;
    ${Screen.ipad} {
        padding: 32px 32px 0;
    }
`;

export const SubHeading = styled.div`
    ${Text.Full.medium.regular};
    color: ${cssVars.fg_gray2_gray3};
    margin-right: 16px;
    flex: 1;
`;

export const CloseButtonWithIcon = styled(CloseIcon)``;

export const CloseButton = styled.div<ICloseIcon>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${cssVars.bg_ash_normal};
    border-radius: ${radius.huge};
    cursor: pointer;

    ${Screen.ipad} {
        width: 40px;
        height: 40px;
    }

    ${Screen.desktop} {
        width: 36px;
        height: 36px;
    }

    &:hover {
        background: ${cssVars.bg_white_dark3};
        box-shadow: ${cssVars.shadow_card_default};
    }
`;
