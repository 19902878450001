import {
    IAvailability,
    TAvailabilityRates,
    TEditMode,
    Ticket,
} from '@/common/service/api/Availability/Availability.domain';
import { TConfig } from '@/common/service/api/Config/Config.domain';
import {
    ReactNode,
    createContext,
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { SERVER_FORMAT_DATE } from '@/common/app/constants/timeConstants';

import { useDateContext } from '@/common/app/contexts/DateContext';
import { IDateRange } from '@/common/domain/Date.domain';
import {
    fetchAvailabilityFullByIds,
    fetchAvailabilityShortByIds,
} from '@/common/service/api/Availability/Availability';
import { fetchConfig } from '@/common/service/api/Config/Config';
import { SessionStorageType } from '@/common/service/storage';
import {
    getSessionStorage,
    removeSessionStorage,
} from '@/common/service/storage/SessionStorage/SessionStorage';
import {
    checkScheduleSoldout,
    createTripflexValue,
    findMinMaxPrices,
    getAvailScheduleID,
    ratesMapper,
    selectedData,
    validateAddToCart,
    validateRate,
} from '@/entities/Activity/app/Creators';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { setStorageCartActivity } from '@/entities/Cart/app/storage/cartStorage';
import { CartData } from '@/entities/Cart/domain/Cart.domain';
import { addCartItem } from '@/entities/Cart/service/addCartItem';
import { trackAddToCart } from '@/shared/Analytics/GA4';
import { checkAndSavePromo } from '@/shared/Layout/service/checkAndSavePromo';
import { getRecommends } from '@/shared/FeaturedActivities/service/ApiFeaturedActivities';
import { IRecommendsItem } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { addDays, format, startOfToday } from 'date-fns';
import { useRouter } from 'next/router';
import { useCartContext } from '@/entities/Cart/app/CartContext';
import { getDateByTimezone } from '../utils/dateUtils';
import { getAnalyticsDataForTrackCart } from '@/shared/Analytics/GA4/helpers';

const DAYS_IN_WEEK = 7;

type ActivityProviderProps = {
    children: ReactNode;
    activity: IActivity;
};

export type Guest = {
    first_name?: string;
    last_name?: string;
};

export type TGuestObject = { [key: string]: { [key: number]: Guest } };

export type TSelected = {
    add_tripflex: number;
    additional_options: { [key: string]: string };
    agreement_confirm: number;
    calendar_id: string;
    comment_guest: string;
    guests: TGuestObject;
    is_gift: string;
    schedule_id: number;
    tickets: { [key: number]: number };
    trip_id: string;
    rate_id: number;
};

type ContextProps = {
    activity: IActivity;
    selected: TSelected;
    availability: TAvailabilityRates;
    month: number[];
    config: TConfig;
    minMaxPrices: number[];
    status: TStatus;
    recommends: IRecommendsItem[];
    isValidRate: boolean;
    shortAvail?: IAvailability;
    changeDay: (newCalendarId: string) => () => void;
    chooseDate: (newDate: IDateRange) => void;
    changeWeek: (type: 'next' | 'prev') => void;
    changeSchedule: (scheduleId: number) => () => void;
    selectTicket: (ticketId: number, count: number) => void;
    selectOption: (optionId: string, valueId: string) => void;
    selectRate: (newRateId: number) => void;
    changeComment: (value: string) => void;
    setError: (error: TValidationError) => void;
    selectPolicy: (value: number) => void;
    selectAgreement: () => void;
    selectGuests: (ticketId: number, countId: number, value: Guest) => void;
    submitAddToCart: () => void;
};

export type TStatus = {
    isValid: boolean;
    isLoading: boolean;
    error: boolean;
    message: string;
    link: string;
    totalPrice: number;
    tripflexValue: number;
    tripflexText: string;
    editModeID: string;
};

export type TValidationError = {
    error: boolean;
    message: string;
    link: string;
};

const DEFAULT_STORE: ContextProps = {
    activity: {} as IActivity,
    isValidRate: false,
    selected: {
        add_tripflex: -1,
        additional_options: {},
        agreement_confirm: 0,
        calendar_id: '',
        comment_guest: '',
        guests: {},
        is_gift: '',
        schedule_id: 0,
        tickets: {},
        trip_id: '',
        rate_id: 0,
    },
    availability: {} as TAvailabilityRates,
    month: [],
    config: {} as TConfig,
    recommends: [],
    minMaxPrices: [],
    status: {
        isLoading: false,
    } as TStatus,
    changeDay: () => () => {
        return;
    },
    changeWeek: () => () => {
        return;
    },
    chooseDate: () => {
        return;
    },
    changeSchedule: () => () => {
        return;
    },
    selectTicket: () => {
        return;
    },
    selectOption: () => {
        return;
    },
    selectRate: () => {
        return;
    },
    changeComment: () => {
        return;
    },
    setError: () => {
        return;
    },
    selectPolicy: () => {
        return;
    },
    selectAgreement: () => {
        return;
    },
    selectGuests: () => {
        return;
    },
    submitAddToCart: () => {
        return;
    },
};

const ActivityContext = createContext<ContextProps>(DEFAULT_STORE);

const ActivityContextProvider = ({ children, activity }: ActivityProviderProps) => {
    const { query, isReady, push } = useRouter();

    const [selected, setSelected] = useState<TSelected>(DEFAULT_STORE.selected);
    const [availability, setAvailability] = useState<TAvailabilityRates>(
        DEFAULT_STORE.availability
    );
    const [recommends, setRecommends] = useState<IRecommendsItem[]>([]);
    const [month, setMonth] = useState<number[]>(DEFAULT_STORE.month);
    const [config, setConfig] = useState<TConfig>(DEFAULT_STORE.config);
    const [minMaxPrices, setMinMaxPrices] = useState<number[]>(DEFAULT_STORE.minMaxPrices);
    const [status, setStatus] = useState<TStatus>(DEFAULT_STORE.status);
    const [shortAvail, setShortAvail] = useState<IAvailability>();

    const { date, nextWeek, prevWeek, changeDate } = useDateContext();

    const { sessionId, addAnalyticsCallback, runToast } = useCartContext();

    const fetchAvailability = useCallback(
        /* eslint-disable sonarjs/cognitive-complexity */
        async (currentActivity: IActivity) => {
            setStatus({ ...status, isLoading: true });
            setShortAvail(undefined);
            const id = currentActivity.id;
            const promocode = await checkAndSavePromo(query.promo, runToast);
            const lastDay = addDays(getDateByTimezone(new Date(date.from)), 6);
            const selectedWeek = { ...date, to: format(lastDay, SERVER_FORMAT_DATE) };

            const reqData = {
                promocode,
                ...selectedWeek,
            };

            const [availRes, newConfig, availShort] = await Promise.all([
                fetchAvailabilityFullByIds({ ...reqData, id }),
                fetchConfig(id),
                fetchAvailabilityShortByIds({ ...reqData, trip_ids: id }),
            ]);

            const newAvail = ratesMapper(availRes);

            if (newAvail.days_info.length < DAYS_IN_WEEK || !+newAvail.use_calendar) {
                newAvail.days_info = Array(DAYS_IN_WEEK)
                    .fill(0)
                    .map((_, index) => {
                        const dayDate = format(
                            addDays(getDateByTimezone(new Date(date.from)), index),
                            SERVER_FORMAT_DATE
                        );

                        if (!+newAvail.use_calendar && newAvail.days_info?.[0]) {
                            return {
                                ...newAvail.days_info[0],
                                id: index.toString(),
                                date: dayDate,
                            };
                        }

                        const dayInDaysInfo = newAvail.days_info.find(
                            (day) => day.date === dayDate
                        );

                        if (dayInDaysInfo) {
                            return dayInDaysInfo;
                        } else {
                            return {
                                id: index.toString(),
                                date: dayDate,
                                is_avail: false,
                                min_price: 0,
                                min_price_subunits: 0,
                                product_city_id: '',
                                product_id: '',
                                product_name: '',
                                tickets_avail: 0,
                                rates: [
                                    {
                                        rate_id: 0,
                                        rate_name: '',
                                        schedules: [],
                                        additional_options: [],
                                        is_avail: false,
                                        tickets_avail: 0,
                                    },
                                ],
                            };
                        }
                    });
            }

            const firstItem =
                newAvail.days_info.find((day) => day.is_avail) || newAvail.days_info[0];

            const minMaxMap = findMinMaxPrices(newAvail.days_info);

            const isAgreement = JSON.parse(currentActivity.data)?.text.agreement;
            const useTripflex = Boolean(+currentActivity?.use_tripflex);

            const newScheduleId = getAvailScheduleID(
                firstItem.rates[0].schedules?.[0]?.id || 0,
                newAvail,
                firstItem.id,
                firstItem.rates[0].rate_id
            );

            const editMode: TEditMode = getSessionStorage(
                SessionStorageType.EDIT_MODE
            ) as TEditMode;
            removeSessionStorage(SessionStorageType.EDIT_MODE);

            const editModeCalendarId =
                editMode?.date && newAvail.days_info.find(({ date }) => date === editMode.date)?.id;
            const editModeTickets =
                editMode?.tickets &&
                Object.fromEntries(
                    Object.entries(editMode?.tickets).map(([key, value]) => [key, Number(value)])
                );
            const editModeOptions =
                editMode?.additional_options &&
                Object.fromEntries(
                    Object.entries(editMode?.additional_options).map(([key, value]) => [key, value])
                );
            const editModeGuests =
                editMode?.guests &&
                Object.fromEntries(
                    Object.entries(editMode.guests).map(([key, value]) => [key, value])
                );

            const newSelected = {
                ...selected,
                calendar_id: editModeCalendarId || firstItem.id,
                schedule_id: (editMode?.schedule_id && +editMode.schedule_id) || newScheduleId,
                trip_id: firstItem.product_id,
                agreement_confirm: isAgreement ? 0 : 1,
                add_tripflex: useTripflex ? -1 : 0,
                rate_id:
                    (editMode?.rate_id && +editMode.rate_id) ||
                    firstItem.rates.find((r) => r.is_avail === true)?.rate_id ||
                    firstItem.rates[0].rate_id,
                tickets: editModeTickets || selected.tickets,
                additional_options: editModeOptions || selected.additional_options,
                guests: editModeGuests || selected.guests,
            };

            const { selectedSchedule } = selectedData(newAvail, newSelected);

            const { value, text } = createTripflexValue(
                selectedSchedule?.tickets || [],
                newSelected.tickets,
                newConfig
            );

            const newStatus = validateAddToCart(currentActivity, newSelected, newAvail, status);

            setMonth(DEFAULT_STORE.month);

            setSelected(newSelected);
            setAvailability(newAvail);
            setShortAvail(availShort[0] || {});
            setConfig(newConfig);
            setMinMaxPrices(minMaxMap);
            setStatus({
                ...status,
                ...newStatus,
                isLoading: false,
                tripflexValue: value,
                tripflexText: text,
                editModeID: editMode?.item_id || status?.editModeID || '',
            });
        },
        [date, selected, status, query.promo, runToast]
    );

    const fetchRecommends = useCallback(async () => {
        try {
            if (date) {
                const data = await getRecommends({
                    destination_id: activity.city_id,
                    from: date.from,
                    to: date.to,
                });
                return setRecommends(data);
            }
        } catch (error) {
            return setRecommends([]);
        }
    }, [activity, date]);

    const isNeedRequest = useMemo(() => {
        const isDifferentDates = Boolean(
            availability.days_info?.[0]?.date && date.from !== availability.days_info?.[0]?.date
        );
        const isDifferentTrips = Boolean(
            availability.days_info?.[0]?.product_id &&
                activity?.id !== availability.days_info?.[0]?.product_id
        );

        return (
            isReady &&
            !status.isLoading &&
            ((date.from && activity?.id && !availability.days_info) ||
                isDifferentDates ||
                isDifferentTrips)
        );
    }, [activity?.id, availability.days_info, date.from, status.isLoading, isReady]);

    const isValidRate = useMemo(() => {
        return validateRate(activity, selected, availability);
    }, [activity, availability, selected]);

    useEffect(() => {
        if (isNeedRequest) {
            fetchAvailability(activity);
            fetchRecommends();
        }
    }, [activity, isNeedRequest, fetchAvailability, fetchRecommends]);

    const contextProviderValue = useMemo(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        () => ({
            activity,
            selected,
            availability,
            month,
            config,
            minMaxPrices,
            status,
            recommends,
            shortAvail,
            isValidRate,
            changeDay: (newCalendarId: string) => () => {
                const dayItem = availability.days_info.find((day) => day.id === newCalendarId);

                if (!dayItem) return;

                const newRateId = dayItem.rates.find((rate) => rate.is_avail)?.rate_id || 0;
                0;
                const newScheduleId = getAvailScheduleID(0, availability, newCalendarId, newRateId);

                const newSelected = {
                    ...selected,
                    calendar_id: dayItem.id,
                    schedule_id: newScheduleId,
                    trip_id: dayItem.product_id,
                    rate_id: newRateId,
                };
                const newStatus = validateAddToCart(activity, newSelected, availability, status);

                const { selectedSchedule } = selectedData(availability, newSelected);

                const { value, text } = createTripflexValue(
                    selectedSchedule?.tickets || [],
                    newSelected.tickets,
                    config
                );

                setStatus({
                    ...status,
                    ...newStatus,
                    tripflexValue: value,
                    tripflexText: text,
                });

                setSelected(newSelected);

                if (window.screen.width < 500) {
                    document.getElementById('calendar-body')?.scrollIntoView();
                }
            },
            changeWeek: (type: 'next' | 'prev') => {
                if (type === 'next') {
                    nextWeek();
                }
                if (type === 'prev') {
                    prevWeek();
                }
            },
            chooseDate: (newDate: IDateRange) => {
                if (newDate.dateStart >= startOfToday()) {
                    changeDate(format(newDate.dateStart, SERVER_FORMAT_DATE));
                }
            },
            changeSchedule: (scheduleId: number) => () => {
                if (scheduleId) {
                    const { selectedRate, selectedSchedule } = selectedData(availability, {
                        ...selected,
                        schedule_id: scheduleId,
                    });

                    const newScheduleID = checkScheduleSoldout(selectedSchedule, selectedRate);

                    const newSelected = {
                        ...selected,
                        schedule_id: newScheduleID,
                    };

                    const { value, text } = createTripflexValue(
                        selectedSchedule?.tickets || [],
                        newSelected.tickets,
                        config
                    );

                    const newStatus = validateAddToCart(
                        activity,
                        newSelected,
                        availability,
                        status
                    );

                    setStatus({
                        ...status,
                        ...newStatus,
                        tripflexValue: value,
                        tripflexText: text,
                    });
                    setSelected(newSelected);
                }
            },
            selectTicket: (ticketId: number, count: number) => {
                const newTickets = {
                    ...selected.tickets,
                    [ticketId]: count,
                };

                if (count < 1) {
                    delete newTickets[ticketId];
                }

                const { selectedSchedule } = selectedData(availability, selected);

                const selectedTickets = selectedSchedule?.tickets as Ticket[];

                const { value, text } = createTripflexValue(selectedTickets, newTickets, config);

                const newSelected = {
                    ...selected,
                    tickets: newTickets,
                };
                if (value < 0) {
                    if (selected.add_tripflex === 0) {
                        newSelected.add_tripflex = 0;
                    } else {
                        newSelected.add_tripflex = -1;
                    }
                }

                const newStatus = validateAddToCart(activity, newSelected, availability, status);

                setSelected(newSelected);

                setStatus({
                    ...status,
                    ...newStatus,
                    tripflexValue: value,
                    tripflexText: text,
                });
            },
            selectOption: (optionId: string, valueId: string) => {
                const newSelected = {
                    ...selected,
                    additional_options: {
                        ...selected.additional_options,
                        [optionId]: valueId,
                    },
                };
                const newStatus = validateAddToCart(activity, newSelected, availability, status);
                setStatus({
                    ...status,
                    ...newStatus,
                });

                setSelected(newSelected);
            },
            selectRate: (newRateId: number) => {
                const { selectedRate, selectedSchedule, selectedDay } = selectedData(availability, {
                    ...selected,
                    rate_id: newRateId,
                });

                const newRate = selectedRate || selectedDay?.rates[0];
                const newScheduleId =
                    checkScheduleSoldout(selectedSchedule, newRate) ||
                    newRate?.schedules?.[0]?.id ||
                    0;

                const newSelected = {
                    ...selected,
                    rate_id: newRate?.rate_id || 0,
                    schedule_id: newScheduleId,
                };

                const newStatus = validateAddToCart(activity, newSelected, availability, status);

                const { selectedSchedule: newSelectedSchedule } = selectedData(
                    availability,
                    newSelected
                );

                const { value, text } = createTripflexValue(
                    newSelectedSchedule?.tickets || [],
                    newSelected.tickets,
                    config
                );

                setStatus({
                    ...status,
                    ...newStatus,
                    tripflexValue: value,
                    tripflexText: text,
                });

                setSelected(newSelected);
            },
            changeComment: (value: string) => {
                setSelected((prev) => ({
                    ...prev,
                    comment_guest: value,
                }));
            },
            setError: (error: TValidationError) => {
                setStatus({
                    ...status,
                    isValid: false,
                    ...error,
                });
            },
            selectPolicy: (value: number) => {
                const newSelected = {
                    ...selected,
                    add_tripflex: value ? 1 : 0,
                };
                const newStatus = validateAddToCart(activity, newSelected, availability, status);
                setSelected(newSelected);
                setStatus({
                    ...status,
                    ...newStatus,
                });
            },
            selectAgreement: () => {
                const newSelected = {
                    ...selected,
                    agreement_confirm: selected.agreement_confirm ? 0 : 1,
                };
                const newStatus = validateAddToCart(activity, newSelected, availability, status);
                setStatus({
                    ...status,
                    ...newStatus,
                });

                setSelected(newSelected);
            },
            selectGuests: (ticketId: number, countId: number, value: Guest) => {
                const newFirstName =
                    typeof value.first_name !== 'undefined'
                        ? value.first_name
                        : selected.guests?.[ticketId]?.[countId]?.first_name || '';

                const newLastName =
                    typeof value.last_name !== 'undefined'
                        ? value.last_name
                        : selected.guests?.[ticketId.toString()]?.[countId]?.last_name || '';

                const newGuests = {
                    ...selected.guests,
                    [ticketId]: {
                        ...selected.guests?.[ticketId],
                        [countId]: {
                            first_name: newFirstName,
                            last_name: newLastName,
                        },
                    },
                };

                const newSelected = {
                    ...selected,
                    guests: newGuests,
                };

                const newStatus = validateAddToCart(activity, newSelected, availability, status);
                setStatus({
                    ...status,
                    ...newStatus,
                });

                setSelected(newSelected);
            },
            submitAddToCart: async () => {
                setStatus({
                    ...status,
                    isLoading: true,
                });

                const { selectedRate, selectedSchedule } = selectedData(availability, selected);

                const tickets = {} as { [key: number]: number };

                selectedSchedule?.tickets.forEach((ticket) => {
                    const selectedTicket = selected.tickets[ticket.id];
                    if (selectedTicket && selectedTicket <= ticket.avail) {
                        tickets[ticket.id] = selectedTicket;
                    }
                });

                const additional_options = {} as { [key: string]: string };

                selectedRate?.additional_options.forEach((option) => {
                    const selectedOption = selected.additional_options[option.id];
                    if (selectedOption) {
                        additional_options[option.id] = selectedOption;
                    }
                });

                const payload = {
                    ...selected,
                    calendar_id: +selected.calendar_id < DAYS_IN_WEEK ? '0' : selected.calendar_id,
                    tickets,
                    additional_options,
                };

                const res = await addCartItem(sessionId as string, payload);

                if (res.success) {
                    setStorageCartActivity(activity);
                    push('/cart/');

                    addAnalyticsCallback?.((data: CartData) =>
                        trackAddToCart(getAnalyticsDataForTrackCart(activity, data))
                    );
                } else {
                    setStatus({
                        ...status,
                        isLoading: false,
                        isValid: false,
                        error: true,
                        message: res.errors?.[0] || '',
                        link: '',
                    });
                }
            },
        }),

        [
            activity,
            selected,
            availability,
            month,
            config,
            minMaxPrices,
            status,
            recommends,
            shortAvail,
            sessionId,
            isValidRate,
            nextWeek,
            prevWeek,
            changeDate,
            push,
            addAnalyticsCallback,
        ]
    );

    return (
        <ActivityContext.Provider value={contextProviderValue}>{children}</ActivityContext.Provider>
    );
};

export const ActivityProvider = memo(ActivityContextProvider);

export const useActivityContext = () => {
    const context = useContext(ActivityContext);

    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }

    return context;
};
