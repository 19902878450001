import { affiliatesInstance } from '@/common/service/api/Axios';
import { CookiesTypes, getCS, saveCS } from '@/common/service/storage';
import { addHours, toDate } from 'date-fns';

const SEVEN_DAYS = 168;

export const createAffCode = (aff: string, path: string) =>
    `${aff}${path.split('/?')[0].replace(/\//g, '_')}`;

export const getAffCode = () => getCS(CookiesTypes.AFFILIATES_CODE);
export const saveAffCode = (value: string) =>
    saveCS(CookiesTypes.AFFILIATES_CODE, value, {
        path: '/',
        expires: toDate(addHours(new Date(), SEVEN_DAYS)),
    });

export const affiliatesAddClick = (code: string) => {
    return affiliatesInstance.get(`/add/click/${code}`);
};
