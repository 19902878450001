import { ReactElement, useState } from 'react';
import { ReviewsCardProps } from './ReviewsCard.types';

import * as S from './ReviewsCard.styles';
import { Button } from '@/common/ui';
import { ResponseModal } from '@/screens/ReviewsPage/ResponseModal/ResponseModal';
import { Rating } from '@/entities/Activity/ui/Rating';

const ReviewsCard = ({ review, innerRef }: ReviewsCardProps): ReactElement => {
    const [show, setShow] = useState(false);

    const displayModal = () => {
        setShow(true);
    };

    const AuthorName = review.user.split(' ', 1);
    const AuthorFamilyName = review.user.split(' ', 2)[1].split('')[0];

    return (
        <S.ReviewCardWrapper ref={innerRef}>
            <S.ContentContainer>
                <S.Title>{review.title}</S.Title>
                <S.Description>{review.content}</S.Description>
                <S.RatingContainer>
                    <Rating rating={review.rating_stars} />
                </S.RatingContainer>
            </S.ContentContainer>
            <S.BottomWrapper>
                <div>
                    <S.Author>
                        {AuthorName} {AuthorFamilyName}.
                    </S.Author>
                    <S.Time>{review.time}</S.Time>
                </div>
                {review.reply && typeof review.reply === 'object' && review.reply.content && (
                    <S.RightPart>
                        <Button variant={'transparent'} onClick={() => displayModal()}>
                            View response
                        </Button>
                    </S.RightPart>
                )}
                <ResponseModal
                    setShow={setShow}
                    show={show}
                    title={review.title}
                    reply={review.reply}
                />
            </S.BottomWrapper>
        </S.ReviewCardWrapper>
    );
};

export { ReviewsCard };
export default ReviewsCard;
