import { BreadcrumbsArrowRight } from '@/shared/images/icons/BreadcrumbsArrowRight';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';

export const Wrapper = styled.div`
    margin: 27px 0 24px;

    ${Screen.ipad} {
        margin: 37px 0 34px;
    }
`;

export const Content = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const BreadcrumbContainer = styled.div<{ isFirst?: boolean }>`
    display: ${({ isFirst }) => (isFirst ? 'none' : 'inline')};
    margin: 0;

    ${Screen.ipad} {
        display: inline;
    }
`;

export const Link = styled.a<{ isLast: boolean }>`
    color: ${({ isLast }) => (isLast ? `${Palette.gray.normal}` : `${Palette.gray.gray3}`)};
    ${Text.Full.large.medium}
    display: inline;
    &:hover {
        text-decoration: underline;
        color: ${Palette.green.normal};
    }
`;

export const WithoutLink = styled.span<{ isLast: boolean }>`
    color: ${({ isLast }) => (isLast ? `${Palette.gray.normal}` : `${Palette.gray.gray3}`)};
    ${Text.Full.large.medium}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ThingsToDoLink = styled.a`
    color: ${Palette.gray.gray3};
    ${Text.Full.large.regular}
    min-width: 88px;
    &:hover {
        text-decoration: underline;
        color: ${Palette.green.normal};
    }
`;

export const CategoryLink = styled.span`
    display: none;
    ${Screen.desktop} {
        display: flex;
        color: ${Palette.gray.gray3};
        ${Text.Full.large.medium}
    }
`;

export const ArrowContainer = styled.div<{ isLast?: boolean }>`
    display: ${({ isLast }) => (isLast ? 'none' : 'inline')};
    align-items: center;
    padding: 0 5px;
`;

export const Arrow = styled(BreadcrumbsArrowRight)<{ isPrev?: boolean }>`
    align-self: center;
    transform: rotate(-90deg);
    color: ${({ isPrev }) => (isPrev ? cssVars.bg_ash_normal : cssVars.bg_blueDark_blue)};
`;
