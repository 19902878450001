import { ReactElement } from 'react';
import { TalkToUsMessage } from '../TalkToUsMessage/TalkToUsMessage';
import * as S from './TalkToUsUnavailable.styles';

const TalkToUsUnavailable = (): ReactElement => {
    return (
        <TalkToUsMessage icon="unavailable">
            <li>We are currently closed.</li>
            Please call us during normal business hours or book <S.Online>online 24/7</S.Online>
            <br />
            <br />
            <li>We are open 7am - 7pm CST Monday-Sunday</li>
        </TalkToUsMessage>
    );
};

export { TalkToUsUnavailable };
