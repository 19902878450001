import styled from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import Text from 'src/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { SearchIcon } from '@/shared/images/icons/SearchIcon';
import { ISearchPreviewWrapperProps } from '@/shared/SearchBar/types/index.types';

export const Wrapper = styled.div<ISearchPreviewWrapperProps>`
    display: ${({ isHomepage, scrollEnd }) => (isHomepage && !scrollEnd ? 'none' : 'flex')};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    ${Screen.desktop} {
        display: ${({ transparent, scrollEnd, isOpenSearch }) =>
            (!transparent || scrollEnd) && !isOpenSearch ? 'flex' : 'none'};
        width: 100%;
        border-radius: ${radius.large};
        min-width: 340px;
        max-height: 35px;
        padding: 3px 4px 3px 10px;
        height: 50px;
        background-color: ${cssVars.bg_white_blueDark};
        margin-right: 32px;
    }
`;

export const SearchContainer = styled.div`
    display: none;
    ${Screen.desktop} {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        text-align: left;
        flex: 1;
        flex-direction: row;
        align-items: center;
        padding: 3px;
    }
`;

export const DestinationBlock = styled.div`
    ${Text.Desktop.medium.medium}
    color: ${cssVars.fg_gray1_white};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0 10px 0 0;
    padding: 0 10px 0 0;
`;

export const CategoryBlock = styled.div`
    ${Text.Desktop.medium.regular}
    color: ${cssVars.fg_gray2_gray3};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const DateBlock = styled.div`
    ${Text.Desktop.small.regular}
    color: ${cssVars.fg_gray2_gray3};
    width: auto;
    line-height: 12px;
    ${Screen.desktop} {
        margin-left: auto;
        white-space: nowrap;
    }
`;

export const SearchButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    cursor: pointer;
    height: 50px;
    ${Screen.desktop} {
        background-color: ${Palette.green.normal};
        width: 28px;
        height: 28px;
        flex: none;
        border-radius: 100%;
    }
`;

export const IconSearch = styled(SearchIcon)`
    display: block;
    margin-right: 8px;
    width: auto;
    height: 18px;
    ${Screen.desktop} {
        margin: 0;
        height: 13px;
    }
`;
