import { Container } from '@/common/ui/containers/Container';
import { createStructuredBreadcrumbList } from '@/entities/Activity/app/Creators';
import Link from 'next/link';

import { ReactElement, memo } from 'react';
import { IBreadcrumbsItem } from '../domain/Breadcrumbs.domain';
import * as S from './Breadcrumbs.styles';

type BreadcrumbsProps = {
    breadcrumbs: IBreadcrumbsItem[];
    mobileMax?: number;
    breadcrumbsAdditionalData?: {
        targetUrl: string;
        targetTitle: string;
    };
};

export const BreadcrumbsContainer = ({
    breadcrumbs,
    mobileMax,
    breadcrumbsAdditionalData,
}: BreadcrumbsProps): ReactElement => {
    const breadcrumbsStructuredData = createStructuredBreadcrumbList(
        breadcrumbs,
        breadcrumbsAdditionalData?.targetUrl,
        breadcrumbsAdditionalData?.targetTitle
    );

    return (
        <S.Wrapper data-test-id="Breadcrumbs">
            <Container>
                <S.Content>
                    {breadcrumbs.map((item, index) => {
                        const isFirst = !index && Boolean(mobileMax);
                        const isLast = index === breadcrumbs.length - 1;
                        const isPrev = index === breadcrumbs.length - 2;

                        return (
                            <S.BreadcrumbContainer key={item.title} isFirst={isFirst}>
                                {item.url && !item.unlink ? (
                                    <Link href={item.url} passHref prefetch={false}>
                                        <S.Link isLast={isLast}>{item.title}</S.Link>
                                    </Link>
                                ) : (
                                    <S.WithoutLink isLast={isLast}>{item.title}</S.WithoutLink>
                                )}
                                <S.ArrowContainer isLast={isLast}>
                                    <S.Arrow isPrev={isPrev} />
                                </S.ArrowContainer>
                            </S.BreadcrumbContainer>
                        );
                    })}
                </S.Content>
                {breadcrumbsStructuredData && (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: breadcrumbsStructuredData }}
                    />
                )}
            </Container>
        </S.Wrapper>
    );
};

export const Breadcrumbs = memo(BreadcrumbsContainer);
