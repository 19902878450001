import { ReactElement, useEffect, useRef, useState } from 'react';
import Link from 'next/link';

import { useWishlistContext, useCartContext, useSearchContext } from '@/common/app/contexts';
import { useScrollingUp } from '../app/useScrollPosition';
import { useMedia } from '@/common/service/hooks/useMedia';

import { Portal } from '@/common/ui/portal';
import { Container } from '@/common/ui/containers/Container/Container';
import { SideMenu } from './SideMenu/SideMenu';
import { TopSearch, SearchPreview } from '@/shared/SearchBar/ui';
import { TalkToUs } from '@/shared/TalkToUs/ui/TalkToUs';
import { utcToZonedTime } from 'date-fns-tz';

import * as S from './Header.styles';
import { useOnClickOutside } from '@/common/service/hooks';

const HEADER_END = 637; //need add global constant or determine by image size

const START_OF_DAY = 7;
const END_OF_DAY = 19;
const WORKING_HOURS = 'avail 8AM-8PM EST';

const Header = ({
    open,
    setOpen,
    transparent,
    isHomepage,
    scrollY,
    pathname,
}: {
    open: boolean;
    setOpen: (num: boolean) => void;
    transparent: boolean;
    isHomepage: boolean;
    scrollY?: number;
    pathname: string;
}): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    const currentHour = utcToZonedTime(new Date(), 'America/Chicago').getHours();

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenTalkToUs, setOpenTalkToUs] = useState(false);
    const [isOpenSearch, setIsOpenSearch] = useState(false);

    const { recents } = useSearchContext();
    const { wishlistCounter } = useWishlistContext();
    const { cartData } = useCartContext();

    const cartItems = cartData.cart_items?.length || 0;
    const scrollEnd = useScrollingUp(scrollY || HEADER_END);
    const isDesktop = useMedia('(min-width: 992px)'); //need add global constant
    const isRecents = recents && !!recents?.length;

    const isPageWithTalkToUs = ['/cart', '/checkout', '/order/voucher', '/order/access'].includes(
        pathname
    );

    const isItWorkingTime = currentHour >= START_OF_DAY && currentHour < END_OF_DAY;

    useOnClickOutside(ref, () => {
        if (isDesktop && isOpenSearch) {
            setIsOpenSearch(false);
        }
    });

    useEffect(() => {
        if (!scrollEnd && transparent) {
            setIsOpenSearch(false);
        }
    }, [scrollEnd, transparent, setIsOpenSearch]);

    return (
        <>
            <S.Wrapper
                id="header"
                transparent={transparent}
                scrolled={scrollEnd}
                isExpanded={isOpenSearch}
                isRecents={isRecents}
                ref={ref}
            >
                <Container data-test-id="header">
                    <S.Header>
                        <S.RightSection>
                            <div
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                <S.ButtonMenu
                                    scrolled={scrollEnd}
                                    transparent={transparent}
                                    data-test-id="Sidebar_button"
                                >
                                    <S.SideBarMenu />
                                </S.ButtonMenu>
                            </div>
                            <Link href="/" passHref>
                                <a aria-label="header logo" data-test-id="Logo">
                                    <S.HeaderLogo />
                                </a>
                            </Link>
                        </S.RightSection>
                        {!isPageWithTalkToUs ? (
                            <S.LeftSection>
                                <SearchPreview
                                    openHandler={() => setIsOpenSearch(true)}
                                    isOpenSearch={isOpenSearch}
                                    isHomepage={isHomepage}
                                    scrollEnd={scrollEnd}
                                    transparent={transparent}
                                />
                                {isDesktop && isOpenSearch && (
                                    <S.LetsFind>{`Let's find your adventure!`}</S.LetsFind>
                                )}
                                <S.SectionIcons isExpanded={isOpenSearch}>
                                    <Link href="/wishlist/" passHref>
                                        <S.CartHeader>
                                            <S.HeartBtn />
                                            {Boolean(wishlistCounter) && (
                                                <S.CartItems>{wishlistCounter}</S.CartItems>
                                            )}
                                        </S.CartHeader>
                                    </Link>
                                    <Link href="/cart/" passHref>
                                        <S.CartHeader>
                                            <S.CartBtn />
                                            {Boolean(cartItems) && (
                                                <S.CartItems>{cartItems}</S.CartItems>
                                            )}
                                        </S.CartHeader>
                                    </Link>
                                </S.SectionIcons>
                            </S.LeftSection>
                        ) : (
                            <S.LeftSection>
                                <S.PhoneIconWrapper onClick={() => setOpenTalkToUs(true)}>
                                    <S.TalkToUsBtnWrapper isItWorkingTime={isItWorkingTime}>
                                        <S.TalkToUsBtn />
                                        <span>Talk to us</span>
                                    </S.TalkToUsBtnWrapper>
                                    <S.PhoneAvailText isItWorkingTime={isItWorkingTime}>
                                        {WORKING_HOURS}
                                    </S.PhoneAvailText>
                                </S.PhoneIconWrapper>
                            </S.LeftSection>
                        )}
                    </S.Header>
                </Container>
                <Container>
                    {isOpenSearch && (
                        <TopSearch
                            isOpen={isOpenSearch}
                            closeHandler={() => setIsOpenSearch(false)}
                            onHandlerSearch={() => setIsOpenSearch(false)}
                        />
                    )}
                </Container>
            </S.Wrapper>
            {isOpen && (
                <Portal>
                    <SideMenu
                        isOpen={isOpen}
                        onChangeOpen={setIsOpen}
                        onTalkToUs={() => setOpenTalkToUs(true)}
                        setOpen={setOpen}
                        open={open}
                    />
                </Portal>
            )}
            {isOpenTalkToUs && <TalkToUs onClose={() => setOpenTalkToUs(false)} />}
        </>
    );
};

export { Header };
